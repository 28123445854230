<template>
  <div class="monitoring-layout">
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <v-row>
      <v-col class="header" cols="12">
        <span class="heading-2">My Health Data </span>
        <v-menu bottom content-class="add-menu" offset-y right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" depressed v-bind="attrs" v-on="on">
              <v-icon class="primary-btn_icon-left" size="20">mdi-plus</v-icon>
              Add My Health Data
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="hasOrder('bloodPressure')" @click="onAdd({ kind: 'bloodPressure' })">
              <v-list-item-title class="templates__edit action-btn"> Blood pressure</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="hasOrder('bloodGlucose')" @click="toggleBloodGlucoseFormOpened">
              <v-list-item-title class="templates__edit action-btn"> Blood glucose</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="hasOrder('bodyTemperature')" @click="toggleBodyTemperatureFormOpened">
              <v-list-item-title class="templates__edit action-btn"> Temperature</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="hasOrder('oxygenSaturation')" @click="toggleOxygenSaturationFormOpened">
              <v-list-item-title class="templates__edit action-btn"> Oxygen Saturation</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="hasOrder('respiratoryRate')" @click="toggleRespiratoryRateFormOpened">
              <v-list-item-title class="templates__edit action-btn"> Respiratory Rate</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="hasOrder('heartRate')" @click="toggleHeartRateFormOpened">
              <v-list-item-title class="templates__edit action-btn"> Heart rate</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <MonitoringCard
      v-for="card in orders"
      :key="card.kind"
      :date="cardDate(card.kind)"
      :isHighlight="isHighlight(card.kind)"
      :item="card"
      :name="card.display"
      :time="cardTime(card.kind)"
      :unit="card.unit"
      :value="cardValue(card.kind)"
      @onAdd="onAdd(card)"
      @onView="onView(card.kind)"
    />
    <Success :dialog="successModal" @close="successModal = false" />
    <Sick :dialog="sickModal" @close="sickModal = false" />
    <AddBodyTemperature @onSave="onOrderAdded" />
    <AddBloodGlucose @onSave="onOrderAdded" />
    <AddBloodPressure @onSave="onOrderAdded" />
    <AddHeartRate @onSave="onOrderAdded" />
    <AddOxygenSaturation @onSave="onOrderAdded" />
    <AddRespiratoryRate @onSave="onOrderAdded" />
  </div>
</template>

<script>
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import { mapActions as mapPiniaActions, mapState as mapPiniaGetters } from "pinia";
import { mapActions, mapState } from "pinia";

import { ServiceRequestApi } from "@/api/serviceRequest";
import { useAuthStore } from "@/pinia-store/auth";
import { useMonitoringStore } from "@/pinia-store/monitoring";
import { useNotificationsStore } from "@/pinia-store/notifications";
import { useObservationsStore } from "@/pinia-store/observations";
import { NotificationTypes } from "@/types/notificationTypes";
import { ObservationEnum } from "@/types/ObservationEnum";
import AddBloodGlucose from "@/views/Patient/Monitoring/bloodGlucose/Add";
import AddBloodPressure from "@/views/Patient/Monitoring/bloodPressure/Add";
import AddBodyTemperature from "@/views/Patient/Monitoring/bodyTemperature/Add";
import AddHeartRate from "@/views/Patient/Monitoring/heartRate/Add";
import AddOxygenSaturation from "@/views/Patient/Monitoring/oxygenSaturation/Add";
import AddRespiratoryRate from "@/views/Patient/Monitoring/respiratoryRate/Add";
import Sick from "@/views/Patient/Monitoring/Sick";
import Success from "@/views/Patient/Monitoring/Success";

import MonitoringCard from "./Card";

export default {
  name: "PatientMonitoring",
  components: {
    MonitoringCard,
    Success,
    Sick,
    AddBloodPressure,
    AddRespiratoryRate,
    AddOxygenSaturation,
    AddHeartRate,
    AddBloodGlucose,
    AddBodyTemperature,
  },
  data() {
    return {
      loading: true,
      tab: "medical-record",
      search: "",
      searchActive: false,
      recentSearches: [],
      searchResult: [],
      searchLoading: false,
      noResults: false,
      successModal: false,
      sickModal: false,
    };
  },
  computed: {
    ...mapState(useMonitoringStore, ["orders"]),
    ...mapState(useObservationsStore, ["summary"]),
    ...mapPiniaGetters(useNotificationsStore, ["monitoringCount", "notifications"]),
    ...mapState(useAuthStore, ["uid"]),

    bloodPressure() {
      return this.summary?.bloodPressure || {};
    },
  },
  methods: {
    ...mapPiniaActions(useNotificationsStore, ["markSeen"]),
    ...mapActions(useObservationsStore, ["getSummary"]),
    ...mapActions(useObservationsStore, ["setPatientId", "changeEditFormField", "clearEditForm"]),
    ...mapActions(useObservationsStore, [
      "toggleHeartRateFormOpened",
      "clearHeartRateForm",
      "toggleBloodPressureFormOpened",
      "clearBloodPressureForm",
      "toggleRespiratoryRateFormOpened",
      "clearRespiratoryRateForm",
      "toggleBloodGlucoseFormOpened",
      "clearBloodGlucoseForm",
      "toggleOxygenSaturationFormOpened",
      "clearOxygenSaturationForm",
      "toggleBodyTemperatureFormOpened",
      "clearBodyTemperatureForm",
    ]),
    ...mapActions(useObservationsStore, [
      "setPatientId",
      "openMonitoringFormByKind",
      "changeEditFormField",
      "clearEditForm",
    ]),
    ...mapActions(useMonitoringStore, ["setOrders"]),
    hasOrder(kind) {
      return this.orders.some((i) => i.kind === kind);
    },
    cardDate(kind) {
      return this.summary?.[kind]?.date || "--";
    },
    kindToOrderNotification(kind) {
      switch (kind) {
        case ObservationEnum.bodyTemperature: {
          return NotificationTypes.BODY_TEMPERATURE_ORDER_ADDED;
        }
        case ObservationEnum.bloodPressure: {
          return NotificationTypes.BLOOD_PRESSURE_ORDER_ADDED;
        }
        case ObservationEnum.heartRate: {
          return NotificationTypes.HEART_RATE_ORDER_ADDED;
        }
        case ObservationEnum.oxygenSaturation: {
          return NotificationTypes.OXYGEN_SATURATION_ORDER_ADDED;
        }
        case ObservationEnum.bloodGlucose: {
          return NotificationTypes.BLOOD_GLUCOSE_ORDER_ADDED;
        }
        case ObservationEnum.respiratoryRate: {
          return NotificationTypes.RESPIRATORY_RATE_ORDER_ADDED;
        }
      }
    },
    isHighlight(kind) {
      kind = this.kindToOrderNotification(kind);
      return (
        uniqBy(
          this.notifications.filter((item) => kind === item.kind && item.seenContent === false),
          "kind",
        ).length > 0
      );
    },
    cardValue(kind) {
      return (this.summary?.[kind]?.value || "--").toString();
    },
    cardTime(kind) {
      return this.summary?.[kind]?.time || "--";
    },
    onAdd(card) {
      this.openMonitoringFormByKind(card.kind);
      this.markSeen({ kind: [this.kindToOrderNotification(card.kind)], seenContent: true, seenBadge: true }).then();
    },
    onAddNew() {
      this.clearEditForm();
      this.changeEditFormField({ field: "showOptions", value: true });
    },
    openSickModal() {
      this.sickModal = true;
    },
    async onView(kind) {
      await this.$router.push(`/patient/monitoring/${kind}`);
      this.markSeen({ kind: [this.kindToOrderNotification(kind)], seenContent: true, seenBadge: true }).then();
    },

    onOrderAdded() {
      if (!this.sickModal) this.successModal = true;
    },
    async load() {
      try {
        const orders = uniqBy(
          orderBy(await ServiceRequestApi.getPatientObservationOrders({ patientId: this.uid, active: true }), [
            "startDate",
          ]),
          (i) => i.kind,
        );
        this.setOrders(orders);
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    },
  },
  async mounted() {
    if (this.orders.length) this.loading = false;
    this.setPatientId(this.uid);
    await this.load();
    if (this.monitoringCount) {
      await this.markSeen({
        kind: [
          NotificationTypes.OXYGEN_SATURATION_ORDER_ADDED,
          NotificationTypes.HEART_RATE_ORDER_ADDED,
          NotificationTypes.RESPIRATORY_RATE_ORDER_ADDED,
          NotificationTypes.BLOOD_PRESSURE_ORDER_ADDED,
          NotificationTypes.BODY_TEMPERATURE_ORDER_ADDED,
          NotificationTypes.BLOOD_GLUCOSE_ORDER_ADDED,
        ],
        seenBadge: true,
      });
    }
    this.loading = false;
  },
};
</script>
<style lang="scss">
.monitoring-layout {
  background: #fafafa;
  padding: 20px 30px;
  padding-bottom: 80px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .healthdata__add-btn {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: normal;
      box-shadow: none;
      text-transform: capitalize;
    }

    .healthdata__item {
      cursor: pointer;
    }
  }
}

.add-btn {
  position: fixed;

  .v-list-item--link {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    transition: 0.2s;

    &:hover {
      color: #2fcf6f;
      background-color: #edfbf4;
    }

    mix-blend-mode: normal;
  }
}
</style>
